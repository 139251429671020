<template>
  <div class="c-no-data-message">{{ message }}</div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.c-no-data-message {
  padding: var(--spacing-m);
  border: var(--box-border);
  border-radius: var(--box-radius);
  text-align: center;
}
</style>
